import React from "react";
import useAddBrand from "./useAddBrand";
import Select from "react-select";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import FileUpload from "../../../Global/FileUpload";

const AddBrand = ({ refetch }) => {
  const {
    basicData,
    isEdit,
    formik,
    status,
    countryCodeOptions,
    logoPreview,
    getFieldError,
    handleLogoRemove,
    handleStatusChange,
    handleCloseModal,
    handleLogoChange,
  } = useAddBrand({ refetch });

  return (
    <div>
      <Input
        label={"Name"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
      />

      <div className="pro-rtl">
        <Input
          label={"(اسم) Name"}
          type="text"
          id="ar_name"
          name="ar_name"
          className={`pro-input lg ${getFieldError("ar_name") && " error"}`}
          {...formik.getFieldProps("ar_name")}
          error={getFieldError("ar_name")}
          errorMessage={getFieldError("ar_name")}
        />
      </div>
      <Input
        label={"Website"}
        type="text"
        id="website_url"
        name="website_url"
        className={`pro-input lg ${getFieldError("website_url") && " error"}`}
        {...formik.getFieldProps("website_url")}
        error={getFieldError("website_url")}
        errorMessage={getFieldError("website_url")}
      />
      <div className="input-wrap pro-mb-4">
        <label
          htmlFor="category"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Category
        </label>
        <Select
          id="category"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("category") && " error"}`}
          classNamePrefix="pro-input"
          name="category"
          options={basicData?.interest}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.category}
          onBlur={formik.handleBlur("category")}
          onChange={(value) => formik.setFieldValue("category", value ?? "")}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("category") && (
          <span className="error-text">{getFieldError("category")}</span>
        )}
      </div>
      <div className="pro-mb-4">
        <FileUpload
          formik={formik}
          title={"Brand Logo"}
          filePreview={logoPreview}
          handleFileChange={handleLogoChange}
          handleFileRemove={handleLogoRemove}
          getFieldError={getFieldError}
          isError={getFieldError("logo")}
        />
        {getFieldError("logo") && (
          <span className="error-custom">{getFieldError("logo")}</span>
        )}
      </div>

      <div className="col-12 pro-mb-4">
        <div className="input-wrap ">
          <label
            htmlFor="description"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Description
          </label>
          <textarea
            id="description"
            rows={3}
            name="description"
            className={`pro-input lg`}
            value={formik?.values?.description ?? ""}
            onChange={(e) =>
              formik.setFieldValue("description", e.target.value)
            }
          ></textarea>
        </div>
      </div>

      <div className="col-12 pro-mb-4">
        <div className="input-wrap pro-rtl">
          <label
            htmlFor="ar_description"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            (الوصف )Description
          </label>
          <textarea
            id="ar_description"
            rows={3}
            name="ar_description"
            className={`pro-input lg`}
            value={formik?.values?.ar_description ?? ""}
            onChange={(e) =>
              formik.setFieldValue("ar_description", e.target.value)
            }
          ></textarea>
        </div>
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="country" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Country
        </label>
        <Select
          id="country"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("country") && " error"}`}
          classNamePrefix="pro-input"
          name="country"
          options={basicData?.country}
          getOptionValue={(option) => option?.unique_country_id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.country}
          onBlur={formik.handleBlur("country")}
          onChange={(value) => formik.setFieldValue("country", value ?? "")}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("country") && (
          <span className="error-text">{getFieldError("country")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4 ">
        <div className="input-country-wrap ">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Contact Number
          </label>
          <div className="input-main-wrap verify-btn-wrap">
            <div className="code-wrap pro-mb-4 ">
              <Select
                id="country_code"
                options={countryCodeOptions}
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option.value}`}
                value={formik?.values?.country_code}
                classNamePrefix="pro-input"
                onBlur={formik.handleBlur("country_code")}
                menuPlacement="auto"
                onChange={(value) =>
                  formik.setFieldValue("country_code", value)
                }
              ></Select>
            </div>
            <input
              type="text"
              id="contact_number"
              name="contact_number"
              className={`pro-input lg ${
                formik.errors.contact_number &&
                formik.touched.contact_number &&
                " error"
              }`}
              {...formik.getFieldProps("contact_number")}
            />
          </div>
          {formik.errors.contact_number && formik.touched.contact_number && (
            <span className="error-text">{formik.errors.contact_number}</span>
          )}
        </div>
      </div>
      <Input
        label={"Email"}
        type="text"
        id="email"
        name="email"
        className={`pro-input lg ${getFieldError("email") && " error"}`}
        {...formik.getFieldProps("email")}
        error={getFieldError("email")}
        errorMessage={getFieldError("email")}
      />
      <div className="pro-toggle">
        <div className="pro-toggle-box ">
          <input
            type="checkbox"
            role="switch"
            id="status"
            checked={status}
            onChange={handleStatusChange}
          />
          <span></span>
        </div>
        <label htmlFor="status">Status</label>
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          type="submit"
          className={"pro-btn-primary lg pro-ms-3 pro-px-5"}
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Add"}
        </Button>
      </div>
    </div>
  );
};

export default AddBrand;
