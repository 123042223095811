import React from "react";
import useChats from "./useChats";
import Style from "./chat.module.scss";
import { Button } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../../Assets";

const Chat = ({ openedChatStatus }) => {
  const { formik, supportState, lastElement, handleClear } = useChats();

  const handleSubmit = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      const textarea = event.target;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const value = textarea.value;
      textarea.value = value.substring(0, start) + "\n" + value.substring(end);
      textarea.selectionStart = textarea.selectionEnd = start + 1;
    } else if (event.key === "Enter" && !event.shiftKey) {
      handleSubmit(event);
    }
  };

  return (
    <div className="row">
      <div className="col-12 pro-mb-4 ">
        <div
          className={
            supportState?.chats?.[supportState?.chatId]?.language === "ar"
              ? "input wrap pro-rtl"
              : "input-wrap"
          }
        >
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Add new message
          </label>
          <textarea
            id="message"
            rows={4}
            name="message"
            className={`pro-input lg ${
              formik.touched.message && formik.errors.message && " error"
            }`}
            onChange={formik?.handleChange("message")}
            value={formik?.values?.message}
            onKeyDown={handleKeyDown}
            style={{ resize: "none" }}
          ></textarea>
          {formik.touched.message && formik.errors.message && (
            <span className="error-text">{formik.errors.message}</span>
          )}
        </div>
        <div
          className={
            supportState?.chats?.[supportState?.chatId]?.language === "ar"
              ? `col-12 pro-d-flex pro-justify-end pro-py-4  pro-mt-auto pro-rtl ${"offcanvas-footer-sticky-btn"}`
              : `col-12 pro-d-flex pro-justify-end pro-py-4  pro-mt-auto  ${"offcanvas-footer-sticky-btn"}`
          }
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={handleClear}
            type="button"
          >
            Clear
          </Button>

          <Button
            className={"pro-btn-primary lg pro-ms-3"}
            onClick={formik?.handleSubmit}
            type="submit"
            disabled={openedChatStatus === "Solved"}
          >
            {formik?.isSubmitting ? (
              <span className="btn-spinner">
                <img src={Assets.SPINNER} alt="spinner" />
              </span>
            ) : (
              "Send"
            )}
          </Button>
        </div>
      </div>
      {supportState?.chatStatus === "pending" &&
      Object?.keys(supportState?.chats)?.length === 0 ? (
        <p>Loading</p>
      ) : (
        <div className={`pro-rtl ${Style.followUp_list_wrap}`}>
          {supportState?.chats?.[supportState?.chatId]?.messages?.map(
            (value, index) => {
              let header = value?.message_owner?.concat(
                `: ${value?.time}, ${value?.created_at}`
              );
              return (
                <div
                  className={
                    supportState?.chats?.[supportState?.chatId]?.language ===
                    "ar"
                      ? `${Style.item_arabic} `
                      : `${Style.item} `
                  }
                  key={index}
                  ref={
                    index ===
                    supportState?.chats?.[supportState?.chatId]?.messages
                      ?.length -
                      5
                      ? lastElement
                      : false || null
                  }
                >
                  <div className={`${Style.list_item}`}>
                    <p
                      className={
                        supportState?.chats?.[supportState?.chatId]
                          ?.language === "ar"
                          ? `pro-font-sm pro-fw-medium pro-mb-0 pro-ltr ${Style.date}`
                          : `pro-font-sm pro-fw-medium pro-mb-0 ${Style.date}`
                      }
                    >
                      {header}
                    </p>
                    <div
                      className={`${Style.list_item_wrap} ${
                        value?.message_type === "admin" ? Style.admin : ""
                      } pro-px-5 pro-py-4`}
                    >
                      {/* badge-success-outline || badge-warning-outline || badge-danger-outline */}
                      <p className="pro-font-sm pro-mb-0">{value?.message}</p>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      )}
    </div>
  );
};

export default Chat;
