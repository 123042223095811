import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getProfileData, updateConfig } from "../../../../Store/Slices/Global";
import { useGetNotificationsListDataQuery } from "../../../../Store/Queries/Notifications";
import { MarkNotificationRead, markNotificationAsRead } from "./api";
import { useEffect } from "react";
import { updateConfig as notificationUpdateConfig } from "../../../../Store/Slices/Notifications/notificationSlice";
import { updateConfig as updateSupportConfig } from "../../../../Store/Slices/Support/suppportSlice";

const useHeaderActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalState = useSelector((state) => state.global);
  const notificationsState = useSelector((state) => state.notifications);

  const { data: notifications = {}, isFetching: notificationsFetching } =
    useGetNotificationsListDataQuery({
      items_per_page: notificationsState.currentPageSize,
      current_page: notificationsState.currentPage,
    });

  useEffect(() => {
    let notificationData = notifications?.data?.data?.filter(
      (item) => item.read_at === null
    );
    if (notificationData?.length > 0) {
      dispatch(
        notificationUpdateConfig((state) => {
          state.isNewNotification = true;
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications?.data]);

  const handleLogout = () => {
    localStorage.removeItem("USER_ACCESS_TOKEN_INVESTO");
    localStorage.removeItem("menu_permissions");
    navigate("/");
  };

  const handleSettings = () => {
    navigate("/Settings");
  };

  const handleViewProfile = () => {
    dispatch(updateConfig((state) => (state.profileModal = true)));
    dispatch(getProfileData());
  };

  const handleCloseProfile = () => {
    dispatch(updateConfig((state) => (state.profileModal = false)));
  };

  const handleNotificationsRedirect = () => {
    navigate("/notifications");
    setTimeout(() => {
      markNotificationAsRead().then((response) => {
        if (response?.data?.code === 200) {
          dispatch(
            notificationUpdateConfig((state) => {
              state.isNewNotification = false;
            })
          );
        }
      });
    }, 1000);
  };

  const handleNotificationRedirect = async (item) => {
    const url = item?.redirection_url;
    const id = item?._id;
    if (item?.redirection_url.includes("/CMS/support")) {
      dispatch(
        updateSupportConfig((state) => {
          state.chatId = item?.redirection_id;
          state.chatModal = true;
        })
      );
    }
    navigate(`${url}`);
    let data = {
      notification_id: id,
    };
    MarkNotificationRead(data).then(() => {});
  };

  return {
    globalState,
    notifications,
    isNewNotification: notificationsState.isNewNotification,
    notificationsFetching,
    handleLogout,
    handleSettings,
    handleViewProfile,
    handleNotificationRedirect,
    handleCloseProfile,
    handleNotificationsRedirect,
  };
};

export default useHeaderActions;
