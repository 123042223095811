import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useTasks from "./useTasks";

const Tasks = ({ formik }) => {
  const { handleCloseModal, handleNext, handleTaskChange, handleArTaskChange } =
    useTasks({
      formik,
    });
  return (
    <div className="pro-d-flex pro-flex-column pro-h-100">
      <div className="col-12 pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Tasks
          </label>
          {/* old with dots */}
          {/* <textarea
            id="tasks"
            rows={3}
            name="tasks"
            className={`pro-input lg ${
              formik.errors.tasks && formik.touched.tasks && "error"
            }`}
            value={formik?.values?.tasks}
            onBlur={formik?.handleBlur("tasks")}
            onChange={(e) => handleTaskChange(e)}
          ></textarea> */}

          {/* without bullet */}
          <textarea
            id="tasks"
            rows={3}
            name="tasks"
            className={`pro-input lg ${
              formik.errors.tasks && formik.touched.tasks && "error"
            }`}
            value={formik?.values?.tasks}
            onBlur={formik?.handleBlur("tasks")}
            onChange={(e) => handleTaskChange(e)}
          ></textarea>
          {formik.touched.tasks && formik.errors.tasks && (
            <span className="error-text">{formik.errors.tasks}</span>
          )}
        </div>
      </div>
      <div className="col-12 pro-mb-4">
        <div className="input-wrap pro-rtl">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            (المهام ) Tasks
          </label>
          <textarea
            id="ar_tasks"
            rows={3}
            name="ar_tasks"
            className={`pro-input lg ${
              formik.errors.ar_tasks && formik.touched.ar_tasks && "error"
            }`}
            value={formik?.values?.ar_tasks}
            onBlur={formik?.handleBlur("ar_tasks")}
            onChange={(e) => handleArTaskChange(e)}
          ></textarea>
          {formik.touched.ar_tasks && formik.errors.ar_tasks && (
            <span className="error-text">{formik.errors.ar_tasks}</span>
          )}
        </div>
      </div>
      <div className="col-12 pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Caption Ideas
          </label>
          <textarea
            id="caption_ideas"
            rows={2}
            name="caption_ideas"
            className={`pro-input lg ${
              formik.errors.caption_ideas &&
              formik.touched.caption_ideas &&
              "error"
            }`}
            value={formik?.values?.caption_ideas}
            onBlur={formik?.handleBlur("caption_ideas")}
            onChange={(e) =>
              formik.setFieldValue("caption_ideas", e.target.value ?? "")
            }
          ></textarea>
          {formik.touched.caption_ideas && formik.errors.caption_ideas && (
            <span className="error-text">{formik.errors.caption_ideas}</span>
          )}
        </div>
      </div>

      <div className="col-12 pro-mb-4">
        <div className="input-wrap pro-rtl">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Caption Ideas (أفكار التسمية التوضيحية )
          </label>
          <textarea
            id="ar_caption_ideas"
            rows={2}
            name="ar_caption_ideas"
            className={`pro-input lg ${
              formik.errors.ar_caption_ideas &&
              formik.touched.ar_caption_ideas &&
              "error"
            }`}
            value={formik?.values?.ar_caption_ideas}
            onBlur={formik?.handleBlur("ar_caption_ideas")}
            onChange={(e) =>
              formik.setFieldValue("ar_caption_ideas", e.target.value ?? "")
            }
          ></textarea>
          {formik.touched.ar_caption_ideas &&
            formik.errors.ar_caption_ideas && (
              <span className="error-text">
                {formik.errors.ar_caption_ideas}
              </span>
            )}
        </div>
      </div>
      <div className="col-12 pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Hash Tag
          </label>
          <textarea
            id="hash_tags"
            rows={2}
            name="hash_tags"
            className={`pro-input lg ${
              formik.errors.hash_tags && formik.touched.hash_tags && "error"
            }`}
            value={formik?.values?.hash_tags}
            onBlur={formik?.handleBlur("hash_tags")}
            onChange={(e) =>
              formik.setFieldValue("hash_tags", e.target.value ?? "")
            }
          ></textarea>
          {formik.touched.hash_tags && formik.errors.hash_tags && (
            <span className="error-text">{formik.errors.hash_tags}</span>
          )}
        </div>
      </div>

      <div className="col-12 pro-mb-4">
        <div className="input-wrap pro-rtl">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Hash Tag (هاش تاغ )
          </label>
          <textarea
            id="ar_hash_tags"
            rows={2}
            name="ar_hash_tags"
            className={`pro-input lg ${
              formik.errors.ar_hash_tags &&
              formik.touched.ar_hash_tags &&
              "error"
            }`}
            value={formik?.values?.ar_hash_tags}
            onBlur={formik?.handleBlur("ar_hash_tags")}
            onChange={(e) =>
              formik.setFieldValue("ar_hash_tags", e.target.value ?? "")
            }
          ></textarea>
          {formik.touched.ar_hash_tags && formik.errors.ar_hash_tags && (
            <span className="error-text">{formik.errors.ar_hash_tags}</span>
          )}
        </div>
      </div>
      <div className="col-12 pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Brand Mentions
          </label>
          <textarea
            id="brand_mentions"
            rows={2}
            name="brand_mentions"
            className={`pro-input lg ${
              formik.errors.brand_mentions &&
              formik.touched.brand_mentions &&
              "error"
            }`}
            value={formik?.values?.brand_mentions}
            onBlur={formik?.handleBlur("brand_mentions")}
            onChange={(e) =>
              formik.setFieldValue("brand_mentions", e.target.value ?? "")
            }
          ></textarea>
          {formik.touched.brand_mentions && formik.errors.brand_mentions && (
            <span className="error-text">{formik.errors.brand_mentions}</span>
          )}
        </div>
      </div>
      <div className="col-12 pro-mb-4">
        <div className="input-wrap pro-rtl">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            (إشارات العلامة التجارية ) Brand Mentions
          </label>
          <textarea
            id="ar_brand_mentions"
            rows={2}
            name="ar_brand_mentions"
            className={`pro-input lg ${
              formik.errors.ar_brand_mentions &&
              formik.touched.ar_brand_mentions &&
              "error"
            }`}
            value={formik?.values?.ar_brand_mentions}
            onBlur={formik?.handleBlur("ar_brand_mentions")}
            onChange={(e) =>
              formik.setFieldValue("ar_brand_mentions", e.target.value ?? "")
            }
          ></textarea>
          {formik.touched.ar_brand_mentions &&
            formik.errors.ar_brand_mentions && (
              <span className="error-text">
                {formik.errors.ar_brand_mentions}
              </span>
            )}
        </div>
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end pro-py-4  pro-mt-auto ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={handleNext}
          type="button"
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default Tasks;
