import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Do from "./Do";
import useDos from "./useDos";
import Dons from "./Dons";

const Dos = ({ formik, getFieldError, handleMoveToErrorTab }) => {
  const {
    isEdit,
    isLiveEdit,
    handleAddDos,
    handleAddDons,
    handleCloseModal,
    handleSubmit,
  } = useDos({ formik, handleMoveToErrorTab });
  return (
    <div className="pro-d-flex pro-flex-column pro-h-100">
      <div className="pro-mb-4">
        {/* <label className="pro-font-sm pro-mb-1 pro-fw-medium">Dos</label> */}
        {formik?.values?.dos?.map((value, index) => {
          return (
            <Do
              formik={formik}
              values={value}
              key={index}
              getFieldError={getFieldError}
              itmIndex={index}
            />
          );
        })}
        <button
          className={"pro-btn pro-btn-outline pro-mt-3 pro-items-center lg"}
          onClick={handleAddDos}
          type="button"
        >
          <span className="material-symbols-outlined">add_circle</span>{" "}
          <span>Add Do's</span>
        </button>
      </div>
      <div>
        {/* <label className="pro-font-sm pro-mb-1 pro-fw-medium">Don'ts</label> */}
        {formik?.values?.dons?.map((value, index) => {
          return (
            <Dons
              formik={formik}
              values={value}
              key={index}
              getFieldError={getFieldError}
              itmIndex={index}
            />
          );
        })}
        <button
          className={"pro-btn pro-btn-outline pro-mt-3 pro-items-center lg"}
          onClick={handleAddDons}
          type="button"
        >
          <span className="material-symbols-outlined">add_circle</span>
          <span>Add Don'ts</span>
        </button>
      </div>

      {!isLiveEdit && (
        <div
          className={`col-12 pro-d-flex pro-justify-end pro-pt-3  pro-mt-5 ${"offcanvas-footer-sticky-btn"}`}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={handleCloseModal}
            type="button"
          >
            Cancel
          </Button>

          <Button
            className={"pro-btn-primary lg pro-ms-3"}
            onClick={handleSubmit}
            type="submit"
          >
            {isEdit ? "Update" : "Submit"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Dos;
