import { getAxiosInstance } from "../../../API";

export const getVerifyData = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `admin/campaign/content-verification-data?user_campaign_id=${params?.user_campaign_id}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const contentVerify = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`admin/campaign/content-verify`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
