import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddAmount from "./useAddAmount";
import Assets from "../../../../Assets";

const AddAmount = ({ refetch }) => {
  const { formik, handleCloseModal, getFieldError } = useAddAmount({ refetch });
  return (
    <div className="row pro-m-3 ">
      <Input
        label={"Amount"}
        type="text"
        id="amount"
        name="amount"
        className={`pro-input lg ${getFieldError("amount") && "error"}`}
        {...formik.getFieldProps("amount")}
        error={getFieldError("amount")}
        errorMessage={getFieldError("amount")}
      />

      <div className="col-12 pro-mb-4">
        <div className="input-wrap">
          <label
            htmlFor="details"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Details
          </label>
          <textarea
            id="details"
            rows={3}
            name="details"
            className={`pro-input lg ${getFieldError("details") && "error"} `}
            value={formik?.values?.details ?? ""}
            onBlur={formik?.handleBlur("details")}
            onChange={(e) => formik.setFieldValue("details", e.target.value)}
          ></textarea>
          {getFieldError("details") && (
            <span className="error-text">{getFieldError("details")}</span>
          )}
        </div>
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className={
            "pro-btn-primary lg pro-ms-3 pro-d-flex pro-align-center pro-gap-2"
          }
          onClick={formik.handleSubmit}
          disabled={formik?.isSubmitting}
        >
          {formik?.isSubmitting ? (
            <span className="btn-spinner">
              <img src={Assets.SPINNER} alt="spinner" />
            </span>
          ) : (
            "Add"
          )}
        </Button>
      </div>
    </div>
  );
};

export default AddAmount;
