import {
  Header,
  Image,
  ModalLayout,
  MultiColumnTable,
  ProfileSideBar,
  SimpleTextDetails,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { Link, Outlet } from "react-router-dom";
import CampaignLayoutSidebarShimmer from "../../Global/Shimmers/CampaignLayoutSidebarShimmer";
import ProtectRoute from "../../../utils/components/ProtectRoute/ProtectRoute";
import HeaderActions from "../../../Pages/Layouts/CommonLayout/HeaderActions";
import Style from "../../../Pages/Layouts/CommonLayout/commonLayout.module.scss";
import userStyle from "./userDetailsLayout.module.scss";
import useCommonLayout from "../../../Pages/Layouts/CommonLayout/useCommonLayout";
import useUserDetailsLayout from "./useUserDetailsLayout";
import Assets from "../../../Assets";
import AddAmount from "./AddAmount";

const UserDetailsLayout = () => {
  const {
    isFetching,
    userData,
    bankDetails,
    nameWithAge,
    basicDetails,
    addAmountModal,
    refetch,
    handleBackClick,
    handleAddAmountClick,
  } = useUserDetailsLayout();

  const { navigations, globalState } = useCommonLayout();
  return (
    <ProtectRoute>
      <Header
        Link={Link}
        navigations={navigations}
        logo={
          globalState.currentTheme === "light" ||
          globalState.currentTheme === null
            ? Assets.HEADERLOGO
            : Assets.HEADERLOGODARK
        }
        children={<HeaderActions />}
        propStyle={{ header: Style.header }}
      />

      <div
        className={`pro-w-100 container-fluid pt-custom ${userStyle.main_wrapper}`}
      >
        <div className={`row gx-0`}>
          {/* side bar left */}
          <div className={`${userStyle.left_sidebar}`}>
            {isFetching &&
            Object.keys(userData?.data?.data ?? {})?.length === 0 ? (
              <>
                <CampaignLayoutSidebarShimmer />
              </>
            ) : (
              <>
                <ProfileSideBar extraClassName={userStyle.left_sidebar_sticky}>
                  <div className={`pro-px-5 pro-pt-5`}>
                    <div
                      className={`pro-py-3 pro-px-2 pro-border-bottom pro-mb-4`}
                    >
                      <button
                        className="pro-back-btn pro-items-center pro-d-flex"
                        onClick={handleBackClick}
                      >
                        <span className="icon">
                          <span className="material-symbols-outlined x4">
                            chevron_left
                          </span>
                        </span>
                        <span className="title">
                          <b>Back to Users</b>
                        </span>
                      </button>
                    </div>
                    <div className={`${userStyle.left_sidebar_sticky_top}`}>
                      <div className={userStyle.profile}>
                        <Image
                          src={`${
                            userData?.data?.data?.image ??
                            Assets.PROFILE_USER_SQURE
                          }`}
                          width={100 || ""}
                          height={100 || ""}
                        />
                      </div>

                      <SimpleTextDetails
                        title={`${nameWithAge ?? ""}`}
                        extraClassNames={`pro-mt-4 pro-mb-1  ${userStyle.profile_name_wrap}`}
                      />
                    </div>
                    <div>
                      <div className={userStyle.brand_container}>
                        <Image
                          src={userData?.data?.brand_logo}
                          alt={"brand_logo"}
                          width={34}
                          height={34}
                          propStyle={{ root: userStyle.image_root }}
                        />
                      </div>
                      <div className="pro-d-flex pro-flex-wrap pro-gap-2">
                        {userData?.data?.data?.interest?.map((value, index) => {
                          return (
                            <span
                              className="pro-badge badge-primary-light"
                              key={index}
                            >
                              {value}
                            </span>
                          );
                        })}
                      </div>
                      {userData?.data?.permissions?.can_add_amount && (
                        <div className="pro-my-4">
                          <button
                            className={
                              "pro-btn pro-d-flex pro-justify-center pro-items-center pro-bg-primary pro-gap-1 "
                            }
                            onClick={handleAddAmountClick}
                          >
                            <span className="material-symbols-outlined x3">
                              wallet
                            </span>
                            Add Amount
                          </button>
                        </div>
                      )}
                    </div>
                    <div
                      className={`${userStyle.left_sidebar_sticky_scrollWrap}`}
                    >
                      <MultiColumnTable
                        title={"Basic Details"}
                        data={basicDetails}
                      />
                    </div>
                    {userData?.data?.data?.bank_account_details && (
                      <div
                        className={`${userStyle.left_sidebar_sticky_scrollWrap}`}
                      >
                        <MultiColumnTable
                          title={"Bank Details"}
                          data={bankDetails}
                          extraClassNames={`pro-mb-2`}
                        />
                      </div>
                    )}
                  </div>
                </ProfileSideBar>
              </>
            )}
          </div>
          <ModalLayout
            show={addAmountModal}
            handleClose={handleAddAmountClick}
            title={"Add Amount"}
            closeIcon={<span className="material-symbols-outlined">close</span>}
          >
            <AddAmount refetch={refetch} />
          </ModalLayout>
          {/* main container */}
          <div className={`${userStyle.main_container}`}>
            <div className={`${userStyle.main_container_inner}`}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </ProtectRoute>
  );
};

export default UserDetailsLayout;
