import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../Store/Slices/Campaign/Creation/CreationSlice";
import { submitAmount } from "../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useGetPaymentDetailsDataQuery } from "../../../../../Store/Queries/Campaign";

const usePaymentSection = ({ listRefetch }) => {
  const { verifyItemId, amount } = useSelector((state) => state.campaignCreate);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState("");

  const { data: paymentDetails, isLoading } = useGetPaymentDetailsDataQuery({
    user_campaign_id: verifyItemId,
  });

  useEffect(() => {
    dispatch(
      updateConfig(
        (state) => (state.amount = paymentDetails?.data?.credit_amount)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleAmountChange = (e) => {
    setErrors("");
    dispatch(updateConfig((state) => (state.amount = e?.target?.value)));
  };

  const handleModalClose = () => {
    dispatch(updateConfig((state) => (state.verifyModal = false)));
    dispatch(updateConfig((state) => (state.verifyTab = "Verify Links")));
  };

  const handleSubmit = () => {
    if (!amount) {
      setErrors("Enter Amount");
    } else if (isNaN(amount) || amount < 1) {
      setErrors("Please enter a valid amount");
    } else {
      setErrors("");
      let data = {
        user_campaign_id: verifyItemId,
        amount: amount,
      };
      submitAmount(data).then((resp) => {
        if (resp?.data?.code === 200) {
          toast.success("Amount credited ");
          handleModalClose();
          listRefetch();
          dispatch(updateConfig((state) => (state.verifyTab = "Verify Links")));
        } else if (resp?.code === 422) {
          let errors = Object.values(resp?.errors || {}).map(
            (val) => val + " "
          );
          setErrors(errors);
        }
      });
    }
  };
  return {
    amount,
    errors,
    paymentDetails,
    handleSubmit,
    handleModalClose,
    handleAmountChange,
  };
};

export default usePaymentSection;
