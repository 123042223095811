import { useNavigate, useParams } from "react-router-dom";
import {
  useGetCampaignDetailsDataQuery,
  useGetCampaignDetailsTableDataQuery,
} from "../../../Store/Queries/Campaign";

import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../Store/Slices/Campaign/Creation/CreationSlice";
import SocialIcons from "../../Global/SocialIcons";
import { toast } from "react-toastify";
import { ExportCampInnerTable } from "../api";
import Style from "./campaignDetails.module.scss";

const useCampaignDetails = () => {
  const { campaignID } = useParams();
  let activeTab = "";
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const [timeLeft, setTimeLeft] = useState("");
  const activeCampaign =
    campaignID.replace(/activeTab=[^&]+/, "") ??
    sessionStorage.getItem("active-campaign");
  const { tableFields } = useSelector((state) => state.global);
  const campaignCreateState = useSelector((state) => state.campaignCreate);
  const navigate = useNavigate();

  const { data: campaignData, isFetching: reportFetching } =
    useGetCampaignDetailsDataQuery({
      campaign_id: activeCampaign,
    });

  const {
    data: TableData,
    isFetching: tableFetching,
    refetch: listRefetch,
  } = useGetCampaignDetailsTableDataQuery(
    {
      campaign_id: activeCampaign,
      status: campaignCreateState.detailsPage.activeTab?.includes(
        "for-approval"
      )
        ? 6
        : campaignCreateState?.detailsPage?.activeTab?.includes("in-progress")
        ? 7
        : campaignCreateState?.detailsPage?.activeTab?.includes("completed")
        ? 2
        : campaignCreateState?.detailsPage?.activeTab?.includes("rejected")
        ? 9
        : campaignCreateState?.detailsPage?.activeTab?.includes("all")
        ? "all"
        : "",

      items_per_page: campaignCreateState?.detailsPage?.currentPageSize,
      page: campaignCreateState?.detailsPage?.currentPage,
      sort_by: campaignCreateState.sortBy,
      sort_order: campaignCreateState.sortOrder,
    },
    { skip: campaignCreateState?.detailsPage?.activeTab === "" }
  );

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = TableData?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [TableData]);

  const url = window.location.href;
  const activeTabParam = "activeTab=";
  const activeTabIndex = url.indexOf(activeTabParam);
  if (activeTabIndex !== -1) {
    const startIndex = activeTabIndex + activeTabParam.length;
    const endIndex =
      url.indexOf("&", startIndex) !== -1
        ? url.indexOf("&", startIndex)
        : url.length;
    activeTab = url.substring(startIndex, endIndex);
    activeTab = decodeURIComponent(activeTab); // Decode the URL component
  }

  useEffect(() => {
    dispatch(
      updateConfig((state) => (state.detailsPage.activeTab = activeTab))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const navigation = [
    {
      label: "All",
      title: "All",
      link: `/campaign-details/${activeCampaign}activeTab="all"`,
      active: activeTab.includes("all"),
    },
    {
      label: "For Approval",
      title: "For Approval",
      link: `/campaign-details/${activeCampaign}activeTab="for-approval"`,
      active: activeTab.includes("for-approval"),
    },
    {
      label: "In Progress",
      title: "In Progress",
      link: `/campaign-details/${activeCampaign}activeTab="in-progress"`,
      active: activeTab.includes("in-progress"),
    },
    {
      label: "Completed",
      title: "Completed",
      link: `/campaign-details/${activeCampaign}activeTab="completed"`,
      active: activeTab.includes("completed"),
    },
    {
      label: "Rejected",
      title: "Rejected",
      link: `/campaign-details/${activeCampaign}activeTab="rejected"`,
      active: activeTab.includes("rejected"),
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      const endDateStr = campaignData?.data?.end_date_with_time;

      const dateParts = endDateStr.split(/[- :]/); // Split the string by '-', ':', and ' ' characters
      const year = parseInt(dateParts[2], 10); // Extract year (2024)
      const month = parseInt(dateParts[1], 10) - 1; // Extract month (April is 3rd month, but JS counts from 0, so subtract 1)
      const day = parseInt(dateParts[0], 10); // Extract day (20)
      let hour = parseInt(dateParts[3], 10); // Extract hour (15)
      const minute = parseInt(dateParts[4], 10); // Extract minute (12)
      const second = parseInt(dateParts[5], 10); // Extract second (16)
      const period = dateParts[6].toLowerCase(); // Extract period (AM/PM)

      // Convert hour to 24-hour format if needed
      if (period === "pm" && hour < 12) {
        hour += 12;
      } else if (period === "am" && hour === 12) {
        hour = 0;
      }

      // Create a new Date object
      const dateObject = new Date(year, month, day, hour, minute, second);

      const endDate = new Date(dateObject);
      const now = new Date();

      const timeDifference = endDate - now;

      if (timeDifference > 0) {
        const totalSeconds = Math.floor(timeDifference / 1000);

        const days = Math.floor(totalSeconds / (3600 * 24)); // Calculate days
        const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600); // Calculate remaining hours after days
        const minutes = Math.floor((totalSeconds % 3600) / 60); // Calculate remaining minutes after hours

        let timeLeftString = "";

        if (days > 0) {
          timeLeftString += `${days} days `;
        }

        if (hours > 0) {
          timeLeftString += `${hours} hrs `;
        }

        if (minutes > 0 || (days === 0 && hours === 0)) {
          timeLeftString += `${minutes} mins`;
        }

        setTimeLeft(timeLeftString.trim() + " Left");
      } else {
        setTimeLeft("Ended");
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [campaignData]);

  const headerLabel = {
    campiagn_condition: "Campaign Status",
    campiagn_budget: "Campiagn Budget",
    used_budget: "Used Budget",
    remaining_budget: "Remaining Budget",
  };
  const HeaderValue = {
    campiagn_condition: `${
      campaignData?.data?.is_live ? timeLeft : "Not Live"
    }`,
    campiagn_budget: `${
      `AED ${campaignData?.data?.budget?.total}` ?? "Unavalable"
    }`,
    used_budget: `${
      `AED ${parseFloat(
        (
          campaignData?.data?.budget?.total -
          campaignData?.data?.budget?.balance
        ).toFixed(2)
      )}` ?? "Unavalable"
    }`,
    remaining_budget: `${
      `AED ${campaignData?.data?.budget?.balance}` ?? "Unavalable"
    }`,
  };

  const headerDetails = Object.keys(HeaderValue).map((key) => {
    return {
      label: headerLabel[key],
      value:
        typeof HeaderValue[key] === "string"
          ? HeaderValue?.[key]
          : typeof HeaderValue[key] === "number"
          ? HeaderValue?.[key]
          : HeaderValue?.[key]?.name,
    };
  });

  const handleStatusClick = (data) => {
    dispatch(
      updateConfig((state) => {
        state.verifyModal = true;
        state.verifyItemId = data?._id;
        state.verifyTab = "Verify Links";
      })
    );
  };

  const handleVerifyModalClose = () => {
    dispatch(updateConfig((state) => (state.verifyModal = false)));
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      social_media: (feild, data) => <SocialIcons icons={data[feild]} />,
      status: (field, data) => (
        <>
          {TableData?.data?.permissions?.can_verify_link ? (
            <p
              className="pro-mb-0 pro-pnt"
              onClick={() =>
                TableData?.data?.permissions?.can_verify_link === true &&
                (data[field] === "In Review" ||
                  data[field] === "Link Approved" ||
                  data[field] === "Link Reject" ||
                  data[field] === "Completed")
                  ? handleStatusClick(data)
                  : ""
              }
            >
              {data[field]}
            </p>
          ) : (
            <p className="pro-mb-0 ">No Permission</p>
          )}
        </>
      ),
      user: (feild, data) => (
        <p
          className={`pro-mb-0 ${
            TableData?.data?.permissions?.can_view_user
              ? `pro-pnt text-truncate ${Style.campaign_title}`
              : ""
          }`}
          onClick={() => {
            if (TableData?.data?.permissions?.can_view_user) {
              navigate({
                pathname: `/user-details/${data?.user_id}`,
              });
              sessionStorage.setItem("active-campaign", `${data?.user_id}`);
            }
          }}
        >
          {data?.[feild]}
        </p>
      ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.detailsPage.currentPageSize = page_size;
        state.detailsPage.budgetcurrentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.detailsPage.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (campaignCreateState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            campaignCreateState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleExportTable = () => {
    let data = {
      status: campaignCreateState.detailsPage.activeTab?.includes(
        "for-approval"
      )
        ? 6
        : campaignCreateState?.detailsPage?.activeTab?.includes("in-progress")
        ? 7
        : campaignCreateState?.detailsPage?.activeTab?.includes("completed")
        ? 2
        : campaignCreateState?.detailsPage?.activeTab?.includes("rejected")
        ? 9
        : campaignCreateState?.detailsPage?.activeTab?.includes("all")
        ? "all"
        : "",
      campaign_id: activeCampaign,
    };
    ExportCampInnerTable(data).then((response) => {
      if (response?.data?.code === 200) {
        toast.success(
          response?.data?.message ?? "Table has send to registered email"
        );
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  return {
    timeLeft,
    tableFields,
    headerDetails,
    tableFetching,
    reportFetching,
    paginationOptions,
    campaignCreateState,
    campaignDetailsTableList: TableData,
    tabNavs: navigation,
    campaignData,
    getRow,
    listRefetch,
    handlePageSize,
    handleExportTable,
    handlePagination,
    handleSort,
    handleVerifyModalClose,
  };
};

export default useCampaignDetails;
