import { createSlice } from "@reduxjs/toolkit";
// import { subDays } from "date-fns";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  showCreateModal: false,
  sortBy: "",
  sortOrder: "",
  search: "",
  activeTab: "all",
  createModal: false,
  isEdit: false,
  isSocialConnectionEdit: false,
  socialConnectionItem: [],
  startDate: null,
  endDate: null,
  // startDate: subDays(new Date(), 30),
  // endDate: subDays(new Date(), -30),
  profileThumb: "",
  selectedIteam: "",
  userDetails: "",
  submitStatus: "idle",
  user: {
    currentPage: 1,
    currentFilter: "all",
    currentPageSize: 10,
    sortBy: "",
    sortOrder: "",
  },
  linkModal: false,
  campId: "",
  formActiveTab: "Overview",
  addAmountModal: false,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = usersSlice.actions;
export default usersSlice.reducer;
