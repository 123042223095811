import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { replay } from "../api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getChatData,
  updateConfig,
} from "../../../../Store/Slices/Support/suppportSlice";
import useObserver from "../../../../utils/hooks/useObserver";

const useChats = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const supportState = useSelector((state) => state.support);

  useEffect(() => {
    if (Object?.keys(supportState?.chats)?.length === 0) {
      dispatch(
        getChatData({
          page: page,
          support_id: supportState?.chatId,
        })
      );
    }
    //eslint-disable-next-line
  }, []);

  const handleLoadMore = () => {
    setPage((prev) => prev + 1);
    dispatch(
      getChatData({
        page: page + 1,
        support_id: supportState?.chatId,
      })
    );
  };

  const { lastElement } = useObserver({
    loading: supportState?.chatStatus === "pending",
    hasMore: 15 * page < supportState?.chatTodal,
    callback: handleLoadMore,
  });

  const formik = useFormik({
    initialValues: {
      message: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values.message) {
        errors.message = "Message Required";
      }
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      let data = {
        support_id: supportState?.chatId,
        message: values?.message,
      };
      replay(data).then((response) => {
        setSubmitting(false);
        if (response?.data?.code === 200) {
          let currentDate = new Date();
          let day = String(currentDate.getDate()).padStart(2, "0");
          let month = String(currentDate.getMonth() + 1).padStart(2, "0");
          let year = currentDate.getFullYear();
          let formattedDate = `${day}-${month}-${year}`;
          let formattedTime = currentDate.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          });
          let message = {
            message: values?.message,
            message_owner: "Admin",
            message_type: "admin",
            created_at: formattedDate,
            time: formattedTime,
          };
          let updatedChats = supportState?.chats?.[supportState?.chatId]
            ?.messages
            ? [
                message,
                ...supportState?.chats?.[supportState?.chatId]?.messages,
              ]
            : [message];
          dispatch(
            updateConfig(
              (state) =>
                (state.chats[supportState?.chatId].messages = updatedChats)
            )
          );
          handleClear();
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });

  const handleClear = () => {
    formik?.handleReset();
  };
  return {
    formik,
    supportState,
    lastElement,
    isFetching: false,
    handleClear,
  };
};

export default useChats;
