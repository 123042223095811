import { useEffect, useState } from "react";
import { useGetDashboardDataQuery } from "../../../Store/Queries/Dashboard";
import { useNavigate } from "react-router-dom";

const useDashboardHome = () => {
  const {
    data: dashboardData = {},
    isFetching,
    isSuccess,
  } = useGetDashboardDataQuery({});

  const navigate = useNavigate();

  const [listingType, setListingType] = useState();

  useEffect(() => {
    if (isSuccess) {
      setListingType(Object.keys(dashboardData?.data || {}) ?? "");
    }
    //eslint-disable-next-line
  }, [isSuccess]);

  const handleClick = (item) => {
    switch (item) {
      case "total_campaigns":
        navigate("/campaign?activeTab=all");
        break;
      case "total_influencers":
        navigate("/user?activeTab=all");
        break;
      case "total_brands":
        navigate("/brand");
        break;
      case "total_supports":
        navigate("/CMS/support");
        break;
      default:
        break;
    }
  };

  let dashboardItems = {
    total_campaigns: {
      label: "total_campaigns",
      title: "Total Campaigns",
      icon: "workspace_premium",
      handleClick,
    },
    total_influencers: {
      label: "total_influencers",
      title: "Total Influencers",
      icon: "group",
      handleClick,
    },
    total_brands: {
      label: "total_brands",
      title: "Total Brands",
      icon: "category",
      handleClick,
    },
    total_supports: {
      label: "total_supports",
      title: "Total Supports",
      icon: "speaker_notes",
      handleClick,
    },
  };
  return {
    dashboardData,
    listingType,
    dashboardItems,
    isFetching,
    isSuccess,
  };
};

export default useDashboardHome;
