import { useNavigate, useParams } from "react-router-dom";
import { useGetUserDetailsDataQuery } from "../../../Store/Queries/Users";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../Store/Slices/User/userSlice";

const useUserDetailsLayout = () => {
  const { userID } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { basicData } = useSelector((state) => state.global);
  const { addAmountModal } = useSelector((state) => state.users);
  const activeUser = userID ?? sessionStorage.getItem("active-user");
  const {
    data: userData,
    isFetching,
    refetch,
  } = useGetUserDetailsDataQuery({
    user_id: activeUser,
  });

  const label = {
    joined: "Joined",
    country: "Country",
    gender: "Gender",
    dob: "DOB",
    phone: "Phone",
    state: "State",
    city: "City", 
    languages: "Languages ",
    balance: "Wallet Balance",
  };

  const phone = userData?.data?.data?.country_code?.concat(
    " " + userData?.data?.data?.mobile
  );

  const languages = userData?.data?.data?.language
    ?.map((id) => {
      const match = basicData?.language?.find((item) => item._id === id);
      return match ? match.name : null;
    })
    .filter((name) => name !== null)
    .join(", ");

  const nameWithAge =
    userData?.data?.data?.name + ` (${userData?.data?.data?.age})`;

  const fomattedDates = (date) => {
    const originalDate = new Date(date);
    const formattedDate = originalDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };

  const value = {
    dob: `${fomattedDates(userData?.data?.data?.dob) ?? "Not specified"}`,
    gender: `${userData?.data?.data?.gender ?? "Not specified"}`,
    joined: `${
      fomattedDates(userData?.data?.data?.created_at) ?? "Not specified"
    }`,
    phone: `${phone ?? "Not specified"}`,
    country: `${userData?.data?.data?.country ?? "Not specified"}`,
    state: `${userData?.data?.data?.state ?? "Not specified"}`,
    city: `${userData?.data?.data?.city ?? "Not specified"}`,
    languages: `${languages ?? "Not specified"}`,
    balance: `${userData?.data?.data?.wallet?.balance ?? "Not specified"}`,
  };

  const basicDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });

  const bankAccountLabel = {
    name: "Name",
    iban_number: "Iban Number",
    bank_name: "Bank Name",
    account_number: "Account Number",
    branch_city: "Branch City",
  };

  const bankAccountValues = {
    name: `${
      userData?.data?.data?.bank_account_details?.name ?? "Not specified"
    }`,
    iban_number: `${
      userData?.data?.data?.bank_account_details?.iban_number ?? "Not specified"
    }`,
    bank_name: `${
      userData?.data?.data?.bank_account_details?.bank_name ?? "Not specified"
    }`,
    account_number: `${
      userData?.data?.data?.bank_account_details?.account_number ??
      "Not specified"
    }`,
    branch_city: `${
      userData?.data?.data?.bank_account_details?.branch_city ?? "Not specified"
    }`,
  };

  const bankDetails = Object.keys(bankAccountValues).map((key) => {
    return {
      label: bankAccountLabel[key],
      value:
        typeof bankAccountValues[key] === "string"
          ? bankAccountValues?.[key]
          : typeof bankAccountValues[key] === "number"
          ? bankAccountValues?.[key]
          : bankAccountValues?.[key]?.name,
    };
  });

  const handleBackClick = () => {
    navigate("/user?activeTab=all");
  };

  const handleAddAmountClick = () => {
    dispatch(
      updateConfig((state) => (state.addAmountModal = !state.addAmountModal))
    );
  };

  return {
    userData,
    isFetching,
    nameWithAge,
    bankDetails,
    basicDetails,
    addAmountModal,
    refetch,
    handleBackClick,
    handleAddAmountClick,
  };
};

export default useUserDetailsLayout;
