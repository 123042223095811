import { useSelector } from "react-redux";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useMemo } from "react";

const useCampaign = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Campaign",
      label: "Campaign",
      link: "/campaign?activeTab=all",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">edit_calendar</span>,
      active: checkIfActiveRoute("/campaign", true),
    },
    {
      title: "Approval",
      label: "Approval",
      link: "/campaign/approval?activeTab=requests",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">how_to_reg</span>,
      active: checkIfActiveRoute("/campaign/approval", true),
    },
    {
      title: "Content Verification",
      label: "Content Verification",
      link: "/campaign/content-verification?activeTab=all",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">new_releases</span>,
      active: checkIfActiveRoute("/campaign/content-verification", true),
    },
  ];
  const drawerMenuPermission = useMemo(() => {
    let menus = Object.values(
      globalState.menu_permissions?.menu_permissions ?? []
    )?.flatMap?.((menu) =>
      menu?.permissions?.map((side_menu) => side_menu?.sub_menu_name)
    );

    return drawerMenu
      ?.filter((menu) => {
        return menus?.includes?.(menu?.label);
      })
      ?.map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.menu_permissions, location.pathname]);

  return {
    drawerMenu: drawerMenuPermission,
  };
};

export default useCampaign;
