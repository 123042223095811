import React from "react";
import Platform from "./Platform";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import useOverview from "./useOverview";
import Select from "react-select";
import Style from "../form.module.scss";
import GoogleMaps from "./GoogleMaps";

const Overview = ({
  formik,
  getFieldError,
  status,
  setStatus,
  content,
  setContent,
}) => {
  let temp = [];
  const {
    tommorrow,
    basicData,
    brands,
    interests,
    handleNext,
    handleCloseModal,
    handleAddPlatform,
    handleStatusChange,
    handleContentChange,
    handleEndDateChange,
    handleStartDateChange,
    handleInterestsChange,
  } = useOverview({ formik, status, setStatus, content, setContent });

  return (
    <div className="pro-d-flex pro-flex-column pro-h-100">
      <Input
        label={"Campaign Name"}
        type="text"
        id="campaign_name"
        name="campaign_name"
        className={`pro-input lg ${getFieldError("campaign_name") && " error"}`}
        {...formik.getFieldProps("campaign_name")}
        error={getFieldError("campaign_name")}
        errorMessage={getFieldError("campaign_name")}
      />

      <div className="pro-rtl">
        <Input
          label={"(اسم الحملة) Campaign Name"}
          type="text"
          id="ar_campaign_name"
          name="ar_campaign_name"
          className={`pro-input lg ${
            getFieldError("ar_campaign_name") && " error"
          }`}
          {...formik.getFieldProps("ar_campaign_name")}
          error={getFieldError("ar_campaign_name")}
          errorMessage={getFieldError("ar_campaign_name")}
        />
      </div>

      <div className="pro-mb-4">
        <label className="pro-font-sm pro-mb-1 pro-fw-medium">
          Social Media
        </label>
        {formik?.values?.platform?.map((value, index) => {
          temp = [...temp, formik?.values?.platform[index]?.p_name];
          return (
            <Platform
              formik={formik}
              values={value}
              key={index}
              getFieldError={getFieldError}
              itmIndex={index}
              basicData={basicData}
              tempIds={temp}
            />
          );
        })}
        {basicData?.social_media?.length !==
          formik?.values?.platform?.length + 1 && (
          <button
            className={"pro-btn pro-btn-outline pro-items-center pro-mt-3 lg"}
            type="button"
            onClick={handleAddPlatform}
            disabled={formik?.values?.platform?.some(
              (item) => item?.p_name?.name?.toLowerCase() === "google"
            )}
          >
            <span className="material-symbols-outlined">add_circle</span>
            <span>Add Platform</span>
          </button>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="region" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Region
        </label>
        <Select
          id="region"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("region") && " error"}`}
          classNamePrefix="pro-input"
          isMulti
          name="region"
          options={
            basicData?.country?.filter((val) => val?.code === "AE")?.[0]
              ?.state ?? []
          }
          getOptionValue={(option) => option?.unique_state_id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.region?.map((v) => v)}
          onBlur={formik.handleBlur("region")}
          onChange={(value) =>
            formik.setFieldValue(
              "region",
              value.map((v) => v)
            )
          }
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("region") && (
          <span className="error-text">{getFieldError("region")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="gender" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Gender
        </label>
        <Select
          id="gender"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("gender") && " error"}`}
          classNamePrefix="pro-input"
          name="gender"
          options={basicData?.gender ?? []}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.gender}
          onBlur={formik.handleBlur("gender")}
          onChange={(value) => formik.setFieldValue("gender", value ?? "")}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("gender") && (
          <span className="error-text">{getFieldError("gender")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label
          htmlFor="campaign_type"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Campaign Type
        </label>
        <Select
          id="campaign_type"
          name="campaign_type"
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("campaign_type") && " error"
          }`}
          classNamePrefix="pro-input"
          options={basicData?.campaign_type ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          value={formik?.values?.campaign_type}
          onBlur={formik.handleBlur("campaign_type")}
          onChange={(value) =>
            formik?.setFieldValue("campaign_type", value || "")
          }
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("campaign_type") && (
          <span className="error-text">{getFieldError("campaign_type")}</span>
        )}
      </div>
      <div className={Style.age}>
        <Input
          label={"From Age"}
          type="text"
          id="from_age"
          name="from_age"
          className={`pro-input lg ${getFieldError("from_age") && " error"}`}
          {...formik.getFieldProps("from_age")}
          error={getFieldError("from_age")}
          errorMessage={getFieldError("from_age")}
        />
        <Input
          label={"To Age"}
          type="text"
          id="to_age"
          name="to_age"
          className={`pro-input lg ${getFieldError("to_age") && " error"}`}
          {...formik.getFieldProps("to_age")}
          error={getFieldError("to_age")}
          errorMessage={getFieldError("to_age")}
        />
      </div>
      {/* removed as per cr */}
      {/* <div className="input-wrap pro-mb-4">
        <label
          htmlFor="post_type"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Post Type
        </label>
        <Select
          id="post_type"
          name="post_type"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("post_type") && " error"}`}
          classNamePrefix="pro-input"
          options={basicData?.post_type ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          value={formik?.values?.post_type}
          onBlur={formik.handleBlur("post_type")}
          onChange={(value) => formik?.setFieldValue("post_type", value || "")}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("post_type") && (
          <span className="error-text">{getFieldError("post_type")}</span>
        )}
      </div> */}
      <div className="input-wrap pro-mb-4">
        <label htmlFor="brand" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Select Brand
        </label>
        <Select
          id="brand"
          name="brand"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("brand") && " error"}`}
          classNamePrefix="pro-input"
          options={brands ?? []}
          getOptionLabel={(option) => {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <image
                  src={option.brand_logo}
                  height={5}
                  width={5}
                  alt={"brand"}
                /> */}
                {option?.brand_name}
              </div>
            );
          }}
          getOptionValue={(option) => option?._id}
          value={formik?.values?.brand}
          onBlur={formik.handleBlur("brand")}
          onChange={(value) => formik?.setFieldValue("brand", value || "")}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("brand") && (
          <span className="error-text">{getFieldError("brand")}</span>
        )}
      </div>

      {/* cr as per review */}
      {/* <div className="input-wrap pro-mb-4">
        <label
          htmlFor="languages"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Languages
        </label>
        <Select
          id="languages"
          name="languages"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("languages") && " error"}`}
          classNamePrefix="pro-input"
          options={basicData?.language ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          value={formik?.values?.languages?.map((v) => v)}
          onBlur={formik.handleBlur("languages")}
          onChange={(value) =>
            formik?.setFieldValue("languages", value.map((v) => v) || [])
          }
          menuPlacement="auto"
          isClearable
          isMulti
        />
        {getFieldError("languages") && (
          <span className="error-text">{getFieldError("languages")}</span>
        )}
      </div> */}
      <div className="input-wrap pro-mb-4">
        <label
          htmlFor="interests"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Interests
        </label>
        <Select
          id="interests"
          name="interests"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("interests") && " error"}`}
          classNamePrefix="pro-input"
          options={interests ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          value={formik?.values?.interests?.map((v) => v)}
          onBlur={formik.handleBlur("interests")}
          onChange={(value) => handleInterestsChange(value)}
          menuPlacement="auto"
          isClearable
          isMulti
        />
        {getFieldError("interests") && (
          <span className="error-text">{getFieldError("interests")}</span>
        )}
      </div>

      <div className="col-12 pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Description
          </label>
          <textarea
            id="description"
            rows={3}
            name="description"
            className={`pro-input lg ${
              formik.errors.description &&
              formik.touched.description &&
              " error"
            }`}
            value={formik?.values?.description ?? ""}
            onBlur={formik?.handleBlur("description")}
            onChange={(e) =>
              formik.setFieldValue("description", e.target.value)
            }
          ></textarea>
          {formik.touched.description && formik.errors.description && (
            <span className="error-text">{formik.errors.description}</span>
          )}
        </div>
      </div>

      <div className="col-12 pro-mb-4">
        <div className="input-wrap pro-rtl">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            (الوصف )Description
          </label>
          <textarea
            id="ar_description"
            rows={3}
            name="ar_description"
            className={`pro-input lg ${
              formik.errors.ar_description &&
              formik.touched.ar_description &&
              " error"
            }`}
            value={formik?.values?.ar_description ?? ""}
            onBlur={formik?.handleBlur("ar_description")}
            onChange={(e) =>
              formik.setFieldValue("ar_description", e.target.value)
            }
          ></textarea>
          {formik.touched.ar_description && formik.errors.ar_description && (
            <span className="error-text">{formik.errors.ar_description}</span>
          )}
        </div>
      </div>
      <div className={`col-12 pro-mb-4`}>
        <label
          htmlFor="start_date"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Start Date
        </label>
        <input
          min={new Date().toISOString().slice(0, 10)}
          className={`pro-input lg  ${getFieldError("start_date") && " error"}`}
          type="date"
          name="start_date"
          placeholder="Select Start Date"
          onChange={handleStartDateChange}
          onBlur={formik?.handleBlur("start_date")}
          value={formik.values.start_date}
        />
        {formik.touched.start_date && formik.errors.start_date && (
          <span className="error-text">{formik.errors.start_date}</span>
        )}
      </div>
      <div className={`col-12 pro-mb-4`}>
        <label
          htmlFor="end_date"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          End Date
        </label>
        <input
          min={tommorrow}
          className={`pro-input lg  ${getFieldError("end_date") && " error"}`}
          type="date"
          name="end_date"
          placeholder="Select End Date"
          onChange={handleEndDateChange}
          onBlur={formik?.handleBlur("end_date")}
          value={formik.values.end_date}
          disabled={formik?.values?.start_date ? false : true}
        />
        {formik.touched.end_date && formik.errors.end_date && (
          <span className="error-text">{formik.errors.end_date}</span>
        )}
      </div>
      <Input
        label={"Allowed days"}
        type="text"
        id="allowed_date"
        name="allowed_date"
        className={`pro-input lg ${getFieldError("allowed_date") && " error"}`}
        {...formik.getFieldProps("allowed_date")}
        error={getFieldError("allowed_date")}
        errorMessage={getFieldError("allowed_date")}
        disabled={
          formik?.values?.start_date && formik?.values?.end_date ? false : true
        }
      />
      <div className="pro-mb-4">
        <GoogleMaps formik={formik} />
      </div>
      {formik.touched.maps && formik.errors.maps && (
        <span className="pro-text-error pro-my-2">{formik.errors.maps}</span>
      )}
      {formik?.values?.platform?.some(
        (item) => item?.p_name?.name?.toLowerCase() === "google"
      ) && (
        <Input
          label={"Distance (KMs)"}
          type="text"
          id="distance"
          name="distance"
          className={`pro-input lg ${getFieldError("distance") && " error"}`}
          {...formik.getFieldProps("distance")}
          error={getFieldError("distance")}
          errorMessage={getFieldError("distance")}
        />
      )}
      <Input
        label={"Location"}
        type="text"
        id="location"
        name="location"
        className={`pro-input lg ${getFieldError("location") && " error"}`}
        {...formik.getFieldProps("location")}
        error={getFieldError("location")}
        errorMessage={getFieldError("location")}
      />

      <div className="pro-rtl">
        <Input
          label={"(الموقع ) Location"}
          type="text"
          id="ar_location"
          name="ar_location"
          className={`pro-input lg ${getFieldError("ar_location") && " error"}`}
          {...formik.getFieldProps("ar_location")}
          error={getFieldError("ar_location")}
          errorMessage={getFieldError("ar_location")}
        />
      </div>
      <div className="col-12 pro-mb-4">
        <div className="input-wrap">
          <label
            htmlFor="address"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Address
          </label>
          <textarea
            id="address"
            rows={3}
            name="address"
            className={`pro-input lg ${
              formik.errors.address && formik.touched.address && " error"
            }`}
            value={formik?.values?.address ?? ""}
            onBlur={formik?.handleBlur("address")}
            onChange={(e) => formik.setFieldValue("address", e.target.value)}
          ></textarea>
          {formik.touched.address && formik.errors.address && (
            <span className="error-text">{formik.errors.address}</span>
          )}
        </div>
      </div>

      <div className="col-12 pro-mb-4">
        <div className="input-wrap pro-rtl">
          <label
            htmlFor="address"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            (العنوان) Address
          </label>
          <textarea
            id="ar_address"
            rows={3}
            name="ar_address"
            className={`pro-input lg ${
              formik.errors.ar_address && formik.touched.ar_address && " error"
            }`}
            value={formik?.values?.ar_address ?? ""}
            onBlur={formik?.handleBlur("ar_address")}
            onChange={(e) => formik.setFieldValue("ar_address", e.target.value)}
          ></textarea>
          {formik.touched.ar_address && formik.errors.ar_address && (
            <span className="error-text">{formik.errors.ar_address}</span>
          )}
        </div>
      </div>

      {/* toggle  */}
      <div className="pro-d-flex pro-gap-5">
        <div className="pro-toggle">
          <div className="pro-toggle-box">
            <input
              type="checkbox"
              role="switch"
              id="status"
              checked={status}
              onChange={handleStatusChange}
            />
            <span></span>
          </div>
          <label>Status</label>
        </div>
        <div className="pro-toggle pro-ms-5 ">
          <div className="pro-toggle-box">
            <input
              type="checkbox"
              role="switch"
              id="status"
              checked={content}
              onChange={handleContentChange}
            />
            <span></span>
          </div>
          <label>Content Verification</label>
        </div>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end pro-py-4  pro-mt-auto ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={handleNext}
          type="button"
        >
          {"Next"}
        </Button>
      </div>
    </div>
  );
};

export default Overview;
