import { useFormik } from "formik";
import countryData from "../../../../utils/countryCode";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/Brand/brandSlice";
import { addBrand, updateBrand } from "./api";
import { toast } from "react-toastify";
import { brand } from "../../../../Store/Queries/Brand";

const useAddBrand = ({ refetch }) => {
  const { basicData } = useSelector((state) => state.global);
  const { logoPreview, isEdit, itemData, selectedItem } = useSelector(
    (state) => state.brand
  );
  const [status, setStatus] = useState(true);
  const dispatch = useDispatch();
  const initialValues = {
    name: "",
    ar_name: "",
    category: "",
    logo: "",
    description: "",
    ar_description: "",
    country: "",
    country_code: "",
    contact_number: "",
    email: "",
    website_url: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Enter Name"),
    ar_name: Yup.string().required("Enter Name (اسم)"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is Required"),
    website_url: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      )
      .required("Website URL is required"),
    //removed .url validation from formik
    category: Yup.object().required("Select Category"),
    country: Yup.object().required("Select Country"),
    contact_number: Yup.string()
      .matches(/^[0-9]{7,14}$/, "Contact number must be 7 to 14 digits")
      .required("Contact Number is required"),
    logo:
      logoPreview !== ""
        ? Yup.mixed()
            .test("fileType", "Invalid file format", (value) => {
              if (value) {
                const supportedFormats = [
                  "image/jpeg",
                  "image/png",
                  "image/svg+xml",
                ];
                return supportedFormats.includes(value.type);
              }
              return true;
            })
            .test("fileSize", "Please Upload file lessthan 2MB", (value) => {
              if (value) {
                return value.size <= 2097152;
              }
              return true;
            })
        : Yup.string().required("Upload Logo"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      let data = {
        name: values?.name,
        ar_name: values?.ar_name,
        category: values?.category?._id,
        country: values?.country?.unique_country_id,
        logo: values?.logo,
        description: values?.description,
        ar_description: values?.ar_description,
        website_url: values?.website_url,
        country_code: values?.country_code?.value,
        shortname: values?.country_code?.code,
        contact_number: values?.contact_number,
        email: values?.email,
        status: status === true ? "Active" : "Inactive",
      };

      let formData = new FormData();
      for (let key in data) {
        if (data[key] !== undefined && data[key] !== null && data[key] !== "") {
          formData.append(key, data[key]);
        }
      }

      if (isEdit) {
        formData.append("id", selectedItem);
        if (values?.logo === "") {
          formData?.delete("logo");
        }
        updateBrand(formData).then((response) => {
          if (response?.status === 200) {
            toast.success("Brand Updated");
            refetch();
            handleCloseModal();
            dispatch(brand.util.invalidateTags(["brand_details"]));
          } else if (response?.code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        addBrand(formData).then((response) => {
          if (response?.status === 200) {
            refetch();
            toast.success("Added Succesfully");
            dispatch(brand.util.invalidateTags(["brandDetail"]));
            handleCloseModal();
          } else if (response?.code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });

  useEffect(() => {
    if (!isEdit) {
      formik?.setFieldValue("country_code", {
        name: "United Arab Emirates",
        code: "AE",
        label: "+971",
        value: "+971",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  useEffect(() => {
    if (isEdit) {
      Object.keys(itemData || {}).forEach((key) => {
        dispatch(
          updateConfig(
            (state) => (state.logoPreview = itemData?.logo_thumb_image)
          )
        );
        if (key === "website") {
          formik.setFieldValue("website_url", itemData?.[key]);
        } else if (key === "category_id") {
          const category = basicData?.interest?.filter(
            (value) => value?._id === itemData?.[key]
          );
          formik.setFieldValue("category", category?.[0]);
        } else if (key === "country") {
          const country = basicData?.country?.filter(
            (value) => value?.name === itemData?.[key]
          );
          formik.setFieldValue("country", country?.[0]);
        } else if (key === "status") {
          itemData?.[key] === "Active" ? setStatus(true) : setStatus(false);
        } else if (key === "logo") {
          formik.setFieldValue("logo", "");
        } else if (key === "country_code") {
          const country = countryData?.filter(
            (value) => value?.value === itemData?.[key]
          );
          formik.setFieldValue("country_code", country?.[0]);
        } else {
          if (key !== "category") {
            formik.setFieldValue(key, itemData?.[key]);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, itemData, basicData]);

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleStatusChange = () => {
    setStatus(!status);
  };

  const handleLogoChange = (event) => {
    const selectedLogo = event?.target?.files[0];
    if (selectedLogo) {
      formik.setFieldValue("logo", selectedLogo);
      const file = selectedLogo;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.logoPreview = reader.result;
          })
        );
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  const handleLogoRemove = () => {
    dispatch(
      updateConfig((state) => {
        state.logoPreview = "";
      })
    );
    formik.setFieldValue("logo", "");
    formik?.setFieldError("logo", "Upload logo");
    formik?.setFieldTouched("logo", true);
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.addModal = false;
        state.logoPreview = "";
      })
    );
  };

  return {
    formik,
    isEdit,
    status,
    countryCodeOptions: countryData,
    basicData,
    logoPreview,
    getFieldError,
    handleStatusChange,
    handleLogoRemove,
    handleLogoChange,
    handleCloseModal,
  };
};

export default useAddBrand;
