/* eslint-disable no-useless-escape */
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import { updateConfig } from "../../../../Store/Slices/User/userSlice";
import { useDispatch, useSelector } from "react-redux";
import OverviewTab from "./Overview";
import PostsTab from "./Posts";
import { useParams } from "react-router-dom";
import { socialConnectionCreate, updateSocialConnection } from "./api";
import { toast } from "react-toastify";
import { users } from "../../../../Store/Queries/Users";

const useSocialConnectForm = ({ refetch, headerData, statusFetching }) => {
  const dispatch = useDispatch();
  const { userID } = useParams();
  const { formActiveTab, isSocialConnectionEdit, socialConnectionItem } =
    useSelector((state) => state.users);
  const { basicData } = useSelector((state) => state.global);
  const [moveToTab, setMoveToTab] = useState(false);
  const [status, setStatus] = useState(true);

  const initialData = {
    social_media: isSocialConnectionEdit
      ? basicData?.social_media?.find(
          (item) =>
            item?.name === socialConnectionItem.social_media?.social_media_name
        ) || {}
      : "",
    profile_url: isSocialConnectionEdit
      ? socialConnectionItem.social_media?.url
      : "",
    followers: isSocialConnectionEdit
      ? socialConnectionItem.social_media?.subscribers_count
      : "",
    post:
      isSocialConnectionEdit && socialConnectionItem?.post?.length > 0
        ? socialConnectionItem.post.map((item) => ({
            post_url: item?.post_url,
            post_type: basicData?.asset_type?.filter(
              (v) => v?.id === item?.post_type
            )?.[0],
            like_count: item?.like_count,
            view_count: item?.view_count,
            comment_count: item?.comment_count,
            post_id: isSocialConnectionEdit ? item?._id : "",
          }))
        : [
            {
              post_url: "",
              post_type: "",
              like_count: "",
              view_count: "",
              comment_count: "",
            },
          ],
  };

  const validation = Yup.object({
    social_media: Yup.object().required("social media required"),
    profile_url: Yup.string()
      .required("Profile Url is required")
      .matches(
        /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
        "invalid URL"
      ),
    followers: Yup.number()
      .required("Enter valid count")
      .typeError("Enter a valid number")
      .integer("Followers count must be an integer"),
    post: Yup.array().of(
      Yup.object().shape({
        post_url: Yup.string()
          .matches(
            /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
            "invalid URL"
          )
          .required("Post URL is required"),
        post_type: Yup.object().required("post type required"),
        like_count: Yup.number()
          .required("like count required")
          .typeError("Enter a valid number")
          .integer("Like count must be an integer"),
        view_count: Yup.number()
          .required("view count required")
          .typeError("Enter a valid number")
          .integer("View count must be an integer"),
        comment_count: Yup.number()
          .required("comment count required")
          .typeError("Enter a valid number")
          .integer("Comment count must be an integer"),
      })
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: validation,
    onSubmit: async (values) => {
      let data = {
        user_id: userID,
        social_media: isSocialConnectionEdit
          ? values?.social_media?.name
          : values?.social_media?.name,
        url: values?.profile_url,
        followers_count: values?.followers,
      };

      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          if (Array.isArray(value)) {
            value.forEach((item, index) => {
              formData.append(`${key}[${index}]`, item);
            });
          } else {
            formData.append(key, value);
          }
        }
      });

      values?.post?.forEach((item, index) => {
        if (item?.post_url)
          formData.append(`post[${index}][post_url]`, item.post_url);
        if (item?.post_type?.id)
          formData.append(`post[${index}][post_type]`, item.post_type.id);
        if (item?.like_count !== undefined)
          formData.append(`post[${index}][like_count]`, item.like_count);
        if (item?.view_count !== undefined)
          formData.append(`post[${index}][view_count]`, item.view_count);
        if (item?.comment_count !== undefined)
          formData.append(`post[${index}][comment_count]`, item.comment_count);

        if (isSocialConnectionEdit && item?.post_id !== undefined) {
          formData.append(`post[${index}][post_id]`, item.post_id);
        }
      });
      if (isSocialConnectionEdit) {
        //edit action
        updateSocialConnection(formData).then((res) => {
          if (res?.status === 200) {
            toast.success(res?.data?.message ?? "Updated Succesfully");
            dispatch(users.util.invalidateTags(["UserConnectionData"]));
            statusFetching();
            refetch();
            dispatch(updateConfig((state) => (state.showCreateModal = false)));
          } else if (res?.status === 422) {
            toast.error("Failed to update connection");
            let errors = res?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
            handleMoveToErrorTab();
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        //create Action
        socialConnectionCreate(formData).then((res) => {
          if (res?.status === 200) {
            toast.success("Social Connection Created Succesfully");
            dispatch(users.util.invalidateTags(["UserConnectionData"]));
            dispatch(updateConfig((state) => (state.showCreateModal = false)));
            statusFetching();
            refetch();
          } else if (res?.status === 422) {
            toast.error("Failed to create connection");
            let errors = res?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
            handleMoveToErrorTab();
          } else {
            toast.error("something went wrong");
          }
        });
      }
    },
  });

  const tabs = [
    {
      label: "Overview",
      completed: calculateCompletionStatus([
        "social_media",
        "profile_url",
        "followers",
      ]),
    },
    {
      label: "Posts",
      completed: calculateCompletionStatus([
        "post_url",
        "post_type",
        "like_count",
        "view_count",
        "comment_count",
      ]),
    },
  ];

  const tabsAndFields = [
    { label: "Overview", fields: ["social_media", "profile_url", "followers"] },
    {
      label: "Posts",
      fields: ["post"],
    },
  ];

  function calculateCompletionStatus(fields) {
    // eslint-disable-next-line array-callback-return
    var completedFields = fields?.filter((field) => {
      if (Array.isArray(formik?.values[field])) {
        return formik?.values[field]?.length > 0;
      } else if (
        field === "post_url" ||
        field === "post_type" ||
        field === "like_count" ||
        field === "comment_count" ||
        field === "view_count"
      ) {
        return !!formik?.values?.post?.[0]?.[field];
      } else {
        return !!formik?.values[field];
      }
    });
    return (completedFields.length / fields.length) * 130;
  }
  const handleTabClick = (tab) => {
    if (tab.label) {
      dispatch(
        updateConfig((state) => {
          state.formActiveTab = tab?.label;
        })
      );
    }
    if (isSocialConnectionEdit) {
      dispatch(
        updateConfig((state) => {
          state.formActiveTab = tab?.label;
        })
      );
    }
    // if (campainId) {
    //   dispatch(
    //     updateConfig((state) => {
    //       state.formActiveTab = tab?.label;
    //     })
    //   );
    // }
  };

  const renderTabContent = () => {
    switch (formActiveTab) {
      case "Overview":
        return (
          <OverviewTab
            formik={formik}
            status={status}
            setStatus={setStatus}
            tabs={tabs}
            getFieldError={getFieldError}
            headerData={headerData}
          />
        );
      case "Posts":
        return (
          <PostsTab
            formik={formik}
            tabs={tabs}
            getFieldError={getFieldError}
            handleSubmit={handleSubmit}
          />
        );

      default:
        return null;
    }
  };

  function setActiveTabByName(active) {
    setMoveToTab(false);
    const updatedTab = tabs.find((tab) => tab?.label === active);
    dispatch(
      updateConfig((state) => {
        state.formActiveTab = updatedTab?.label;
      })
    );
  }

  const handleMoveToErrorTab = () => {
    const firstErrorTab = Object.keys(formik.errors)[0];
    if (firstErrorTab === "Overview") {
      setActiveTabByName("Overview");
      return "";
    }
    if (firstErrorTab === "Posts") {
      setActiveTabByName("Posts");
      return "";
    }

    if (firstErrorTab && firstErrorTab !== formActiveTab) {
      const errorTab = tabsAndFields.find((tab) =>
        tab.fields.includes(firstErrorTab)
      );
      setActiveTabByName(errorTab?.label);
    }
    setMoveToTab(true);
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  useEffect(() => {
    if (moveToTab === true) {
      handleMoveToErrorTab();
    }
    setMoveToTab(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.errors, moveToTab]);

  const handleSubmit = (e) => {
    e.preventDefault();
    formik.setTouched(
      Object.keys(formik.values).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {})
    );
    formik.setFieldTouched(`post[${0}].post_type`, true);
    formik.setFieldTouched("social_media", true);

    handleMoveToErrorTab();
    formik.handleSubmit();
  };

  return {
    tabs,
    formik,
    activeTab: formActiveTab,
    handleTabClick,
    renderTabContent,
    handleSubmit,
  };
};

export default useSocialConnectForm;
