import { getAxiosInstance } from "../../../API";

export const addWalletAmount = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/users/add-amount`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
