import { createSlice } from "@reduxjs/toolkit";
import { subDays } from "date-fns";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  search: "",
  activeTab: "all",
  createModal: false,
  isEdit: false,
  selectedData: "",
  formActiveTab: "Overview",
  campainId: "",
  verifyModal: false,
  isPaymentDisabled: false,
  verifyItemId: "",
  detailsPage: {
    activeTab: "",
    currentPage: 1,
    currentFilter: "all",
    currentPageSize: 10,
    sortBy: "",
    sortOrder: "",
  },
  uploadStatus: {
    banner: "",
    assetsImages: "",
    assetsVides: "",
    refImages: "",
    refVideos: "",
  },
  values: {
    banner: {
      file: "",
      thumb: "",
    },
    assetsImages: [
      {
        file: "",
        thumb: "",
      },
    ],
    assetsVideos: [
      {
        file: "",
        thumb: "",
      },
    ],
    refImages: [
      {
        file: "",
        thumb: "",
      },
    ],
    refVideos: [
      {
        file: "",
        thumb: "",
      },
    ],
  },
  errors: {
    banner: "",
    assetsImages: "",
    assestsVideos: "",
    refImages: "",
    refVideos: "",
  },
  startDate: subDays(new Date(), 30),
  endDate: subDays(new Date(), -30),
  verifyTab: "Verify Links",
  selectedIteamId: "",
  campaignStopModal: false,
  maps: {
    lat: 23.4241,
    lng: 53.8478,
    place: "",
    ar_place: "",
  },
  selectedLocation: {
    lat: "",
    lng: "",
    place: "",
    ar_place: "",
  },
  filter: {
    modal: false,
    campaignType: "",
    interests: [],
    status: "",
    liveStatus: "",
    paymentType: "",
    toDate: "",
    fromDate: "",
    liveStatusData: [
      {
        id: 1,
        name: "Live",
      },
      {
        id: 2,
        name: "Not Live",
      },
    ],
  },
  amount: "",
  isLiveEdit: "",
};

const campaignCreateSlice = createSlice({
  name: "campaignCreate",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    resetValuesAndErrors: (state) => {
      state.values.banner = { file: "", thumb: "" };
      state.values.assetsImages = [{ file: "", thumb: "" }];
      state.values.assetsVideos = [{ file: "", thumb: "" }];
      state.values.refImages = [{ file: "", thumb: "" }];
      state.values.refVideos = [{ file: "", thumb: "" }];
      state.errors.banner = "";
      state.errors.assetsImages = "";
      state.errors.assetsVideos = "";
      state.errors.refImages = "";
      state.errors.refVideos = "";
    },
  },
});

export const { updateConfig, resetValuesAndErrors } =
  campaignCreateSlice.actions;
export default campaignCreateSlice.reducer;
