import React from "react";
import Style from "./influencerEarnings.module.scss";

const InfluencerEarnings = ({ value, index, formik,selectedOption }) => {
  return (
    <div className={`${Style.influencer_conatiner}`}>
      <div className="pro-d-flex pro-rounded-3 pro-justify-between pro-p-3">
        <div
          className={`pro-w-auto ${Style.platform_box} pro-p-3 pro-rounded-3 text-truncate pro-fw-medium`}
        >
          {value?.p_name?.name && (
            <>
              {value?.p_name?.name?.charAt(0).toUpperCase() +
                value?.p_name?.name?.slice(1).toLowerCase()}
            </>
          )}
        </div>
        <div className="pro-d-flex pro-items-center pro-gap-3">
          <p className=" pro-mb-0 pro-fw-medium">{selectedOption==='flat'? 'Per Post':'Per Engagement'}</p>
          <div className={`input-wrap ${Style.input_wrap}`}>
            <input
              type="text"
              id={`influencer_earnings${[index]}.amount`}
              name={`influencer_earnings${[index]}.amount`}
              className={`pro-input lg ${
                formik.errors.influencer_earnings?.[index]?.amount &&
                formik.touched?.influencer_earnings?.[index]?.amount &&
                "error"
              }`}
              value={formik?.values?.influencer_earnings?.[index]?.amount}
              onBlur={formik?.handleBlur(`influencer_earnings.${index}.amount`)}
              onChange={(e) =>
                formik.setFieldValue(
                  `influencer_earnings.${index}.amount`,
                  e.target.value
                )
              }
              disabled={
                formik?.values?.platform?.[index]?.p_name ? false : true
              }
            />
            <p
              className={`pro-font-sm pro-fw-medium pro-mb-0 ${Style.currency}`}
            >
              AED
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfluencerEarnings;
