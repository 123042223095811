import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetSocialMediaStatusQuery,
  useGetUserCollabDataQuery,
  useGetUserHeaderDataQuery,
  users,
} from "../../../Store/Queries/Users";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../Store/Slices/User/userSlice";
import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import { useEffect, useState } from "react";
import SocialIcons from "../../Global/SocialIcons";
import {
  viewSocialConnectionItem,
  deleteSocialConnection,
} from "./SocialConnectForm/api";
import { toast } from "react-toastify";
import { exportUserInnerTable } from "./api";
import Style from "./userDetails.module.scss";

const useUserDetails = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const { userID } = useParams();
  const dispatch = useDispatch();
  const usersState = useSelector((state) => state.users);
  const { isSocialConnectionEdit } = useSelector((state) => state.users);
  const { tableFields, basicData } = useSelector((state) => state.global);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteStream, setDeleteStream] = useState("");

  const activeUser = userID ?? sessionStorage.getItem("active-user");
  const {
    data: userCollabDataList,
    isFetching,
    refetch,
  } = useGetUserCollabDataQuery({
    user_id: activeUser,
    items_per_page: usersState?.user?.currentPageSize,
    current_page: usersState?.user?.currentPage,
    sort_by: usersState?.user?.sortBy,
    sort_order: usersState?.user?.sortOrder,
  });

  const navigate = useNavigate();
  const { data: headerData, isFetching: headerFetching } =
    useGetUserHeaderDataQuery({
      user_id: activeUser,
    });
  const { data: socialMediaStatus, refetch: statusFetching } =
    useGetSocialMediaStatusQuery(activeUser);

  let newHeaderData = headerData?.data?.data?.map((item) => {
    return {
      stream: item?.stream,
      socialData: [
        {
          label: "Category",
          value: item?.category ?? "",
        },
        {
          label: "Followers",
          value: item?.subscribers_count ?? 0,
        },
        {
          label: "View Engagement",
          value: item?.view_engagement ?? 0,
        },
        {
          label: "Like Engagement",
          value: item?.like_engagement ?? 0,
        },
        {
          label: "Comment Engagement",
          value: item?.comment_engagement ?? 0,
        },
        {
          label: "Posts",
          value: item?.post_count ?? 0,
        },
      ],
    };
  });

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = userCollabDataList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [userCollabDataList]);

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.user.currentPageSize = page_size;
        state.user.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.user.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (usersState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.user.currentPage = 1;
          state.user.sortOrder =
            usersState?.user?.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.user.currentPage = 1;
          state.user.sortBy = label;
          state.user.sortOrder = "asc";
        })
      );
    }
  };

  const handleLinkCloseClick = () => {
    dispatch(updateConfig((state) => (state.linkModal = false)));
  };

  const handleLinkClick = (data) => {
    dispatch(
      updateConfig((state) => {
        state.linkModal = true;
        state.campId = data?._id;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      link: (_, data) => (
        <>
          {
            <>
              {data?.status === "Completed" ||
              data?.status === "In Review" ||
              data?.status === "Link Reject" ||
              data?.status === "Link Approved" ? (
                <p
                  className="pro-mb-0 pro-pnt"
                  onClick={() => handleLinkClick(data)}
                >
                  {"Click here"}
                </p>
              ) : (
                <span className={"badge-pending-outline pro-badge"}>
                  <p className="pro-mb-0">{"No Link"}</p>
                </span>
              )}
            </>
          }
        </>
      ),
      social_media: (feild, data) => <SocialIcons icons={data[feild]} />,
      status: (feild, data) => {
        return (
          <>
            {data[feild] === "Active" ? (
              <span className={"badge-success-outline pro-badge"}>
                {data[feild]}
              </span>
            ) : (
              <span className={"badge-warning-outline pro-badge"}>
                {data[feild]}
              </span>
            )}
          </>
        );
      },
      campaign_unique_id: (feild, data) => (
        <p
          className={`pro-mb-0 ${
            userCollabDataList?.data?.permissions?.can_view_campaign
              ? `pro-pnt text-truncate ${Style.campaign_title}`
              : ""
          }`}
          onClick={() => {
            if (userCollabDataList?.data?.permissions?.can_view_campaign) {
              navigate({
                pathname: `/campaign-details/${data?.campaign_id}activeTab="all"`,
              });
              sessionStorage.setItem("active-campaign", `${data?.campaign_id}`);
            }
          }}
        >
          {data?.[feild]}
        </p>
      ),
      brand: (feild, data) => (
        <p
          className={`pro-mb-0 ${
            userCollabDataList?.data?.permissions?.can_view_brand
              ? `pro-pnt text-truncate ${Style.campaign_title}`
              : ""
          }`}
          onClick={() => {
            if (userCollabDataList?.data?.permissions?.can_view_brand) {
              navigate({
                pathname: `/brand-details/${data?.brand_id}`,
              });
              sessionStorage.setItem("active-campaign", `${data?.brand_id}`);
            }
          }}
        >
          {data?.[feild]}
        </p>
      ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  //function to open create modal
  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.isSocialConnectionEdit = false;
        state.formActiveTab = "Overview";
        state.socialConnectionItem = [];
      })
    );
  };

  //function to close create modal
  const handleCloseCreateModal = () => {
    dispatch(updateConfig((state) => (state.showCreateModal = false)));
  };

  //function to edti the header item
  const handleEditAction = (data, data1, data2) => {
    dispatch(
      updateConfig((state) => {
        state.formActiveTab = "Overview";
        state.isSocialConnectionEdit = true;
        state.showCreateModal = true;
      })
    );

    const details = {
      user_id: userID,
      social_media: data.stream,
    };

    viewSocialConnectionItem(details).then((res) => {
      if (res?.status === 200) {
        dispatch(
          updateConfig((state) => {
            state.socialConnectionItem = res?.data?.data;
          })
        );
      } else {
        toast.error("Error fetching user details");
      }
    });
  };

  const handleDeleteClick = (value) => {
    setShowDeleteModal(!showDeleteModal);
    if (value) {
      setDeleteStream(value.stream);
    }
  };

  const handleDeleteAction = () => {
    const details = {
      user_id: userID,
      social_media: deleteStream,
    };
    deleteSocialConnection(details).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message ?? "Connection Deleted Successfully");
        setDeleteStream("");
        dispatch(users.util.invalidateTags(["UserConnectionData"]));
        setShowDeleteModal(!showDeleteModal);
        refetch();
      } else {
        toast.error("Error Deleting Social Connection");
      }
    });
  };

  //checking whether all the social media items are used
  const createLimit = basicData?.social_media?.filter(
    (item) => !headerData?.data?.data?.some((v) => item?.name === v?.stream)
  );

  //checking create permission
  const hasCreatePermission = useMemo(() => {
    const permissions = headerData?.data?.permissions ?? {};
    return permissions["can_create"] ?? "";
    //eslint-disable-next-line
  }, [headerData]);

  //checking view permission
  const hasViewPermission = useMemo(() => {
    const permissions = headerData?.data?.permissions ?? {};
    return permissions["can_view"] ?? "";
    //eslint-disable-next-line
  }, [headerData]);

  //checking remove permission
  const hasRemovePermission = useMemo(() => {
    const permissions = headerData?.data?.permissions ?? {};
    return permissions["can_remove"] ?? "";
    //eslint-disable-next-line
  }, [headerData]);

  //checking update permission
  const hasUpdatePermission = useMemo(() => {
    const permissions = headerData?.data?.permissions ?? {};
    return permissions["can_update"] ?? "";
    //eslint-disable-next-line
  }, [headerData]);

  const handleExportTable = () => {
    let data = {
      user_id: activeUser,
    };
    exportUserInnerTable(data).then((response) => {
      if (response?.data?.code === 200) {
        toast.success(
          response?.data?.message ?? "Table has send to registered email"
        );
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  return {
    usersState,
    isFetching,
    tableFields,
    headerFetching,
    userCollabDataList,
    paginationOptions,
    headerData: newHeaderData,
    createLimit,
    showDeleteModal,
    hasCreatePermission,
    hasViewPermission,
    hasRemovePermission,
    hasUpdatePermission,
    socialMediaStatus,
    isSocialConnectionEdit,
    getRow,
    refetch,
    handleSort,
    handlePageSize,
    handlePagination,
    handleExportTable,
    handleEditAction,
    handleCreateClick,
    handleDeleteClick,
    handleDeleteAction,
    handleLinkCloseClick,
    handleCloseCreateModal,
    statusFetching,
  };
};

export default useUserDetails;
