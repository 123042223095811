import React from "react";
import DashboardHero from "../../../Dashboard/DashboardHero";
import Style from "../../../Dashboard/DashboardHome/dashboardListing.module.scss";
import useCardHero from "./useCardHero";

const CardHero = () => {
  const { dashboardData, dashboardItems, isFetching, listingType, children } =
    useCardHero();
  return (
    <div className={`${Style.dashboard_wrap} pro-px-0`}>
      <div className={`pro-w-100`}>
        <DashboardHero
          data={dashboardData?.data}
          obj={dashboardItems}
          loading={isFetching}
          listingType={listingType}
          shimmerCount={4}
        />
      </div>
      {dashboardData?.data && Object.keys(dashboardData?.data)?.length !== 0 ? (
        children
      ) : (
        <>{/* <EmptyData /> */}</>
      )}
    </div>
  );
};

export default CardHero;
