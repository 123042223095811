import {
  Button,
  DatePickerWithInput,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import useFilter from "./useFilter";
import Select from "react-select";

const Filter = () => {
  const {
    formik,
    basicData,
    campaignState,
    getFieldError,
    handleCloseModal,
    handleStatusChange,
    handleToDateChange,
    handleFromDateChange,
    handleInterestsChange,
    handleLiveStatusChange,
    handlePaymentTypeChange,
    handleCampaignTypeChange,
  } = useFilter();
  return (
    <div className="col">
      <div className="pro-mb-4">
        <label className="pro-font-sm pro-mb-1 pro-fw-medium">From Date</label>
        <DatePickerWithInput
          CalendarOutlineIcon={
            <span className="material-symbols-outlined">calendar_month</span>
          }
          range={false}
          initialValue={formik?.values?.fromDate}
          onDateChange={handleFromDateChange}
          placeholder={"Select Date"}
          maxDate={formik?.values?.toDate && formik?.values?.toDate}
        ></DatePickerWithInput>
      </div>
      <div className="pro-mb-4">
        <label className="pro-font-sm pro-mb-1 pro-fw-medium">To Date</label>
        <DatePickerWithInput
          CalendarOutlineIcon={
            <span className="material-symbols-outlined">calendar_month</span>
          }
          range={false}
          initialValue={formik?.values?.toDate}
          onDateChange={handleToDateChange}
          placeholder={"Select Date"}
          minDate={formik?.values?.fromDate}
        ></DatePickerWithInput>
      </div>
      <div className="input-wrap pro-mb-4">
        <label
          htmlFor="campaignType"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Campaign Type
        </label>
        <Select
          id="campaignType"
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("campaignType") && " error"
          }`}
          classNamePrefix="pro-input"
          name="campaignType"
          options={basicData?.campaign_type}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.campaignType}
          onBlur={formik.handleBlur("campaignType")}
          onChange={(value) => handleCampaignTypeChange(value)}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("campaignType") && (
          <span className="error-text">{getFieldError("campaignType")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label
          htmlFor="liveStatus"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Live Status
        </label>
        <Select
          id="liveStatus"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("liveStatus") && " error"}`}
          classNamePrefix="pro-input"
          name="liveStatus"
          options={campaignState?.filter?.liveStatusData}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.liveStatus}
          onBlur={formik.handleBlur("liveStatus")}
          onChange={(value) => handleLiveStatusChange(value ?? "")}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("liveStatus") && (
          <span className="error-text">{getFieldError("liveStatus")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="status" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Status
        </label>
        <Select
          id="status"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("status") && " error"}`}
          classNamePrefix="pro-input"
          name="status"
          options={basicData?.campaign_status}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.status}
          onBlur={formik.handleBlur("status")}
          onChange={(value) => handleStatusChange(value ?? "")}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("status") && (
          <span className="error-text">{getFieldError("status")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label
          htmlFor="PaymentType"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Payment Type
        </label>
        <Select
          id="PaymentType"
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("PaymentType") && " error"
          }`}
          classNamePrefix="pro-input"
          name="PaymentType"
          options={basicData?.payment_type}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.paymentType}
          onBlur={formik.handleBlur("PaymentType")}
          onChange={(value) => handlePaymentTypeChange(value ?? "")}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("PaymentType") && (
          <span className="error-text">{getFieldError("PaymentType")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label
          htmlFor="interests"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Interests
        </label>
        <Select
          id="interests"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("interests") && " error"}`}
          classNamePrefix="pro-input"
          isMulti
          name="interests"
          options={basicData?.interest ?? []}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.interests?.map((v) => v)}
          onBlur={formik.handleBlur("interests")}
          onChange={(value) => handleInterestsChange(value)}
          menuPlacement="auto"
          isClearable
        />
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end pro-py-4  pro-mt-auto offcanvas-footer-sticky-btn`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={formik?.handleSubmit}
          type="submit"
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default Filter;
