import { combineReducers } from "@reduxjs/toolkit";
import globalSlice from "./Slices/Global";
import campaignCreateSlice from "./Slices/Campaign/Creation/CreationSlice";
import { dashboard } from "./Queries/Dashboard";
import { campaign } from "./Queries/Campaign";
import { brand } from "./Queries/Brand";
import campaignApprovalSlice from "./Slices/Campaign/Approval/campaignApprovalSlice";
import brandSlice from "./Slices/Brand/brandSlice";
import { payments } from "./Queries/Payments";
import paymentsSlice from "./Slices/Payments/paymentsSlice";
import userSlice from "./Slices/User/userSlice";
import { users } from "./Queries/Users";
import { interests } from "./Queries/Interests";
import interestsSlice from "./Slices/Interests/interestsSlice";
import { FAQ } from "./Queries/FAQ";
import FAQSlice from "./Slices/FAQ/FAQSlice";
import suppportSlice from "./Slices/Support/suppportSlice";
import { support } from "./Queries/Support";
import adminsSlice from "./Slices/Admins/adminsSlice";
import { admins } from "./Queries/Admins";
import { roles } from "./Queries/Roles";
import rolesSlice from "./Slices/Roles/rolesSlice";
import termsSlice from "./Slices/Terms/termsSlice";
import { terms } from "./Queries/Terms";
import policySlice from "./Slices/Policy/policySlice";
import { policy } from "./Queries/Policy";
import { notifications } from "./Queries/Notifications";
import notificationSlice from "./Slices/Notifications/notificationSlice";
import interestChangeSlice from "./Slices/InterestChange/interestChangeSlice";
import contentVerificationSlice from "./Slices/Campaign/ContentVerification/contentVerificationSlice";

const appReducer = combineReducers({
  [FAQ.reducerPath]: FAQ.reducer,
  [brand.reducerPath]: brand.reducer,
  [users.reducerPath]: users.reducer,
  [roles.reducerPath]: roles.reducer,
  [terms.reducerPath]: terms.reducer,
  [policy.reducerPath]: policy.reducer,
  [admins.reducerPath]: admins.reducer,
  [support.reducerPath]: support.reducer,
  [payments.reducerPath]: payments.reducer,
  [campaign.reducerPath]: campaign.reducer,
  [dashboard.reducerPath]: dashboard.reducer,
  [interests.reducerPath]: interests.reducer,
  [notifications.reducerPath]: notifications.reducer,
  FAQ: FAQSlice,
  users: userSlice,
  brand: brandSlice,
  terms: termsSlice,
  roles: rolesSlice,
  admins: adminsSlice,
  policy: policySlice,
  global: globalSlice,
  support: suppportSlice,
  payments: paymentsSlice,
  interests: interestsSlice,
  notifications: notificationSlice,
  campaignCreate: campaignCreateSlice,
  interestChange: interestChangeSlice,
  campaignApproval: campaignApprovalSlice,
  contentVerification: contentVerificationSlice,
});

export default appReducer;
